<template>
  <div>
    <div id="customerService" @click="dakai" v-if="isShowKai">客户服务中心</div>
    <div id="box" v-if="isShow" @mouseleave="rightOut()">
      <div class="box-right" @mouseleave="rightOut()">
        <div
          @click="guanbi"
          class="box-left"
          @mouseenter="righthover()"
          @mouseleave="rightOut()"
        >
          <img
              @dragstart.prevent
            src="../assets/toright.png"
            v-if="rightIndex == 1"
            style="width: 23px; height: 23px"
          />
          <img
              @dragstart.prevent
            src="../assets/toright2.png"
            v-if="rightIndex == 2"
            style="width: 23px; height: 23px;cursor: pointer;"
          />
        </div>
        <div
          class="torighttitle"
          style="font-weight: bolder; font-size: 18px; margin-top: 10px"
        >
          <img
              @dragstart.prevent
            src="@/assets/code.png"
            style="width: 17px; height: 17px; margin-top: -3px;"
          />
          微信咨询
        </div>
        <img @dragstart.prevent class="toright" src="../assets/weixin.jpg" />
        <div
          style="
            margin-left: 13%;
            margin-top: 5px;
            color: gray;
            font-size: 15px;
          "
        >
          微信扫码或长按识别
        </div>
        <div class="line"></div>
        <div
          style="
            margin-left: 6%;
            font-weight: bolder;
            font-size: 18px;
            display: flex;
          "
        >
          <img
            src="../assets/message.png" @dragstart.prevent
            style="width: 24px; height: 25px; margin-top: -1px"
          />
          <div>在线沟通</div>
        </div>
        <a
          class="texthover toright"
          :href="
            'https://wpa.qq.com/msgrd?v=3&uin=' +
            bottomList.tencent +
            '&site=qqq&menu=yes'
          "
          target="_blank"
        >
          <div style="font-size: 15px">点击QQ咨询</div>
        </a>
        <div class="line"></div>
        <div
          class="torighttitle"
          style="font-weight: bolder; font-size: 18px; display: flex"
        >
          <img
            src="@/assets/write.png" @dragstart.prevent
            style="width: 20px; height: 19px; margin-top: 1px"
          />
          <div>预约咨询</div>
        </div>
        <div class="onSubscribe" @click="centerDialogVisible = true">
          提交预约
        </div>
        <div class="line"></div>
        <div style="font-weight: bolder; font-size: 18px; margin-left: 10%">
          <img
            src="../assets/phone.png" @dragstart.prevent
            style="
              width: 20px;
              height: 20px;
              margin-right: -6px;
              margin-top: -3px;
            "
          />
          电话咨询
        </div>
        <div
          style="
            margin-bottom: 10px;
            margin-left: 20%;
            margin-top: 5px;
            color: gray;
            font-size: 15px;
          "
        >
          {{ bottomList.phone }}
        </div>
      </div>
    </div>
    <el-dialog
      title="请填写预约内容"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      v-if="centerDialogVisible"
      @close="eldialogClose"
    >
      <el-form ref="ruleForm" :model="query" label-width="80px" :rules="rules">
        <el-form-item label="联系人" prop="nickName">
          <el-input
            v-model="query.nickName"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="query.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="notes">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
            v-model="query.notes"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center; align-items: center">
        <el-button
          style="width: 100px; height: 30px; line-height: 0px"
          type="primary"
          @click="buttonClick('ruleForm')"
          >提交</el-button
        >
        <el-button
          style="width: 100px; height: 30px; line-height: 0px"
          @click="centerDialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reservationApi, bottomChange } from "@/api/musicScore";
export default {
  name: "keFu",
  data() {
    return {
      query: {
        nickName: "",
        phone: "",
        notes: "",
      },
      bottomList: {},
      rules: {
        nickName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            pattern:
              /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        notes: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      isShow: true,
      isShowKai: true,
      centerDialogVisible: false,
      rightIndex: 1,
    };
  },
  methods: {
    dakai() {
      this.isShow = true;
      this.isShowKai = false;
      this.rightIndex = 1;
    },
    guanbi() {
      this.isShow = false;
      this.isShowKai = true;
    },
    eldialogClose(){
      this.query = {
        nickName: "",
        phone: "",
        notes: ""
      }
    },
    buttonClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          reservationApi(this.query)
            .then(() => {
              this.centerDialogVisible = false;
              this.$message({
                showClose: true,
                message: "提交成功!",
                type: "success",
              });
            })
            .catch(() => {
              this.centerDialogVisible = false;
            });
        } else {
          return false;
        }
      });
    },
    righthover() {
      this.rightIndex = 2;
    },
    rightOut() {
      this.rightIndex = 1;
    },
  },
  created() {
    bottomChange().then((res) => {
      this.bottomList = res.data;
    });
  },
  watch: {
    $route(to) {
      if (
        to.path == "/createMusic" ||
        to.path == "/musicScore" ||
        to.path == "/videoPlaying"
      ) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
#customerService {
  transition: all 0.5s;
  background: white;
  width: 30px;
  position: fixed;
  right: 0px;
  top: 300px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //align-items: center;
  border-width: 1px 0px 1px 1px;
  border-color: #409eff;
  border-style: solid;
  box-shadow: -3px 3px 3px -3px rgb(190, 190, 192);
  padding: 32px 0;
  font-size: 18px;
  text-align: center;
  img {
    width: 100px;
    height: 100px;
  }
}
#customerService:hover {
  transition: all 0.5s;
  cursor: pointer;
  background: #409eff;
  color: white;
  width: 30px;
  position: fixed;
  right: 0px;
  top: 300px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //align-items: center;
  box-shadow: -3px 3px 3px -3px rgb(190, 190, 192);
  padding: 32px 0;
  font-size: 18px;
  text-align: center;
  img {
    width: 100px;
    height: 100px;
  }
}
#box {
  .box-left {
    transition: all 0.5s;
    background: white;
    position: fixed;
    height: 20px;
    right: 9px;
    top: 309px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    //align-items: center;
    padding: 14px 0;
  }
  .box-left:hover {
    transition: all 0.5s;
    background: white;
    position: fixed;
    height: 20px;
    right: 9px;
    top: 309px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 14px 0;
    font-size: 20px;
    color: blue;
  }
  .box-right {
    transition: all 0.5s;
    background: white;
    width: 188px;
    height: 380px;
    position: fixed;
    right: 0;
    top: 300px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    //align-items: center;
    box-shadow: 0px 0px 3px rgb(190, 190, 192);
    padding: 14px 0;
    font-size: 14px;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
/deep/.el-dialog__title {
  line-height: 24px;
  font-size: 16px;
  color: #303133;
  font-weight: bold;
}
.onSubscribeText {
  margin-left: 5px;
}
.onSubscribeText:hover {
  color: blue;
  text-decoration: underline;
}
.onSubscribe {
  cursor: pointer;
  font-size: 15px;
  margin-left: 21%;
  margin-top: 10px;
  color: gray;
}
.onSubscribe:hover {
  color: blue;
  text-decoration: underline;
}
.texthover:hover {
  color: blue;
  text-decoration: underline;
}
.line {
  margin-bottom: 10px;
  margin-top: 10px;
  border-top: 1px solid lightgray;
  width: 90%;
  margin-left: 5%;
}
.toright {
  margin-left: 20%;
  margin-top: 5px;
  color: gray;
}
.torighttitle {
  font-weight: bolder;
  font-size: 18px;
  margin-left: 9%;
}
</style>
