<template>
  <div class="phonefooter" :style="'background:' + colour">
    <div class="phoneFooter">
      <div class="title2">服务项目</div>
      <div class="routeList">
        <div
          class="routeItem"
          v-for="item in routeList"
          :key="item.id"
          @click="$router.push(item.url)"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="zixun">
        <div class="bottomBox">
          <div class="title2">客户服务中心</div>
          <div class="content">
            <div class="wx">
              <img @dragstart.prevent src="@/assets/weixin.jpg" alt="" />
              <div>微信扫码或长按识别</div>
            </div>
            <div class="qq"   style="cursor: pointer;">
              <a @click="go(bottomList.tencent)">
                <img @dragstart.prevent src="@/assets/qq.png" alt="" />
              </a>
              <div>
                <a style="cursor: pointer;" @click="go(bottomList.tencent)"><div>QQ在线点击咨询</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="footerBox">
          <div class="one" @click="SubmitAppointment">
            <div>预约咨询</div>
            <div>提交预约</div>
          </div>
          <div class="two">
            <div>电话咨询</div>
            <div>{{ bottomList.phone }}</div>
          </div>
          <div class="shareBox" >
            <div @click="shareShow = !shareShow">分享有惊喜</div>
            <div class="btn" @click="shareShow = !shareShow">分享</div>
            <van-dialog v-model="shareShow" title="分享列表">
              <div style="height: 150px; padding: 20px 50px">
                <share v-show="shareShow"></share>
              </div>
            </van-dialog>
          </div>
        </div>
      </div>

      <div class="friend" v-if="bottomList.link">
        <span>友情链接：</span>
        <span
          ><a :href="bottomList.link" style="color: #fff">{{
            bottomList.link
          }}</a></span
        >
        <!-- <span>网易</span> -->
      </div>
    </div>
    <div class="warnBox">
      <p>郑重警告：{{ bottomList.warn }}</p>
      <!-- <p>严禁盗用！严禁抄袭！侵权必</p> -->
      <p class="colors">{{ bottomList.company }}</p>
      <p class="colors">
        <a href="https://beian.miit.gov.cn">{{ bottomList.filings }}</a>
      </p>
    </div>
    <!-- 预约内容 -->
    <el-dialog
      title="请填写预约内容"
      :visible.sync="centerDialogVisible"
      append-to-body
      center
      v-if="centerDialogVisible"
      @close="edialogClose"
    >
      <el-form ref="ruleForm" :model="query" label-width="80px" :rules="rules">
        <el-form-item label="联系人" prop="nickName">
          <el-input
            v-model="query.nickName"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="query.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="notes">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
            v-model="query.notes"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center; align-items: center">
        <el-button
          style="width: 100px; height: 30px; line-height: 0px"
          type="primary"
          @click="buttonClick('ruleForm')"
          >提交</el-button
        >
        <el-button
          style="width: 100px; height: 30px; line-height: 0px"
          @click="centerDialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  reservationApi,
  backgroundColor,
  bottomChange,
} from "@/api/musicScore";
export default {
  name: "phone-footer",
  data() {
    return {
      centerDialogVisible: false,
      routeList: [
        { url: "/phone/createMusic?id=1", id: 1, name: "音乐制作" },
        { url: "/phone/musicComposition?id=2", id: 2, name: "曲谱排版" },
        { url: "/phone/bookComposition?id=3", id: 3, name: "图书排版" },
        { url: "/phone/plushing?id=4", name: "出版印刷", id: 4 },
        { url: "/phone/musicScore?id=5", id: 5, name: "曲谱库" },
        { url: "/phone/membercenter?id=6", id: 6, name: "会员服务" },
        { url: "/phone/recruit?id=7", id: 7, name: "招业务员" },
        { url: "/phone/about?id=8", id: 8, name: "关于我们" },
      ],
      colour: "",
      bottomList: {},
      query: {
        nickName: "",
        phone: "",
        notes: "",
      },
      rules: {
        nickName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            pattern:
              /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        notes: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      show: false,
      shareShow: false,
    };
  },
  mounted() {
    backgroundColor().then((res) => {
      this.colour = res.data.colour;
    });
    bottomChange().then((res) => {
      this.bottomList = res.data;
    });
  },
  methods: {
    edialogClose() {
      this.query = {
        nickName: "",
        phone: "",
        notes: "",
      };
    },
    putKonge(Text){
      const oneText = Text.toString()
      return oneText.replace(/ /g ,'')
    },
    isIOS(){
      return /iphone|iPad/i.test(navigator.userAgent)
    },
    go(qqNum){
      if(this.isIOS()){
        document.location.href = 'https://wpa.qq.com/msgrd?v=3&uin='+this.putKonge(qqNum)+'&site=qqq&menu=yes'
      }else{
        window.open('https://wpa.qq.com/msgrd?v=3&uin='+this.putKonge(qqNum)+'&site=qqq&menu=yes')
      }
    },
    //点击提交预约
    SubmitAppointment() {
      this.centerDialogVisible = true;
      this.show = false;
    },
    buttonClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          reservationApi(this.query)
            .then(() => {
              this.centerDialogVisible = false;
              this.$message({
                showClose: true,
                message: "提交成功!",
                type: "success",
              });
            })
            .catch(() => {
              this.centerDialogVisible = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  margin: 0 auto;
  width: 75% !important;
}
.phoneFooter {
  padding: 30px 27px 5px 27px;
  color: #fff;
  .title2 {
    font-weight: 600;
    font-size: 18px;
  }
  .routeList {
    // margin-top: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .routeItem {
      margin-top: 10px;
      width: 21%;
      font-size: 1rem;
      font-weight: 500;
      display: inline-block;
      text-align-last: justify;
    }
  }
  .friend {
    font-size: 14px;
    margin-top: 20px;
    font-weight: 600;
    span {
      margin-right: 5px;
    }
  }
}
.warnBox {
  padding: 0 5% 10px;
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
  p {
    margin-bottom: 6px;
    font-weight: 600;
  }
  .colors {
    margin-top: 9px;
    // color: #5C5C5C ;
    a {
      color: #fff;
    }
  }
}
.footer {
  margin-top: 15px;
}
.bottomBox {
  margin-top: 20px;
  .title2 {
    font-size: 18px;
    font-weight: 600;
  }
  .content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .wx {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-bottom: 7px;
        width: 90px;
        height: 90px;
      }
    }
    .qq {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a {
        color: #fff;
        // font-size: 15px;
      }
      &:active {
        color: #7cb4e9;
      }
      img {
        margin-bottom: 5px;
        width: 75px;
        height: 90px;
      }
    }
  }
}
.footerBox {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .one {
    // width: 20%;
    &:active {
      color: #7cb4e9;
    }
  }
  .two {
    text-align: center;
    // width: 34%;
    // margin-left: 6%;
    &:active {
      color: #7cb4e9;
    }
  }
  .shareBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn {
      width: 60%;
      background: #fff;
      height: 16px;
      line-height: 16px;
      color: #7cb4e9;
      text-align: center;
      // padding: 2px 0;
      border: 1px solid #eee;
      font-size: 12px;
      border-radius: 5px;
      &:active {
        background: #7cb4e9;
        color: #fff;
      }
    }
  }
  // justify-content: space-between;
  // align-items: center;
}
</style>