<template>
  <div class="footer" :style="'background-color:' + colour">
    <div class="container">
      <div class="footer_top">
        <div class="footer_left">
          <div class="footer_text">
            <div style="margin-bottom: 15px">服务项目</div>
            <div @click="shareClick">
              <router-link
                class="footer_text_hover"
                :to="{ path: '/createMusic', query: { id: 1 } }"
                >音乐制作</router-link
              >
            </div>
            <div @click="shareClick">
              <router-link
                class="footer_text_hover"
                :to="{ path: '/musicComposition', query: { id: 2 } }"
                >曲谱排版</router-link
              >
            </div>
            <div @click="shareClick">
              <router-link
                class="footer_text_hover"
                :to="{ path: '/bookComposition', query: { id: 3 } }"
                >图书排版</router-link
              >
            </div>
            <div @click="shareClick">
              <router-link
                class="footer_text_hover"
                :to="{ path: '/plushing', query: { id: 4 } }"
                >出版印刷</router-link
              >
            </div>
          </div>
          <div class="footer_text">
            <div @click="shareClick">
              <router-link
                class="footer_text_hover"
                :to="{ path: '/musicScore', query: { id: 5 } }"
                >曲谱库</router-link
              >
            </div>
            <div @click="shareClick">
              <router-link
                class="footer_text_hover"
                :to="{ path: '/membercenter', query: { id: 6 } }"
                >会员服务</router-link
              >
            </div>
            <div @click="shareClick">
              <router-link
                class="footer_text_hover"
                :to="{ path: '/recruit', query: { id: 7 } }"
                >招业务员</router-link
              >
            </div>
            <div @click="shareClick">
              <router-link
                class="footer_text_hover"
                :to="{ path: '/about', query: { id: 8 } }"
                >联系我们</router-link
              >
            </div>
          </div>
          <div class="footer_text_footer">
            <div>客户服务中心</div>
            <img @dragstart.prevent src="../assets/weixin.jpg" />
            <div style="font-size: 14px">微信扫码或长按识别</div>
          </div>
        </div>
        <div class="footer_right">
          <div class="qq">
            <a
            @click="go(bottomList.tencent)" style="cursor: pointer;"
            >
              <img @dragstart.prevent src="../assets/QQ2.png" style="cursor: pointer" />
            </a>
            <div class="gapHover">
              <a
              @click="go(bottomList.tencent)" style="cursor: pointer;"
              >
                <div class="footer_gap_hover">QQ在线</div>
              </a>
              <a
              @click="go(bottomList.tencent)" style="cursor: pointer;"
              >
                <div class="footer_QQ_hover">点击咨询</div>
              </a>
            </div>
          </div>
          <div class="gapHover">
            <div style="cursor: pointer" @click="centerDialogVisible = true">
              预约咨询
            </div>
            <div class="footer_QQ_hover" @click="centerDialogVisible = true">
              提交预约
            </div>
          </div>
          <div class="gap">
            <div>电话咨询</div>
            <div>{{ bottomList.phone }}</div>
          </div>
          <div>
            <div
              class="footer_border"
              @mouseover="show = true"
              @mouseleave="show = false"
            >
              <!--            分享到其它平台-->
              分享有惊喜
              <div
                class="share"
                @mouseover="show = true"
                @mouseleave="show = false"
              >
                <div class="share_div">分享</div>
                <share v-show="show"></share>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="linkStyle" v-if="bottomList.link">
          友情链接：<a :href="bottomList.link">{{ bottomList.link }}</a>
        </div>
        <div class="warn">郑重警告：{{ bottomList.warn }}</div>
        <div class="warn_text">
          <div>
            地址：{{ bottomList.address }}&nbsp;&nbsp;&nbsp;电话：{{
              bottomList.phone
            }}&nbsp;&nbsp;&nbsp;
            <a
              class="qqvxStyle"
              @click="go(bottomList.tencent)" style="cursor: pointer;"
              >QQ/微信：{{ bottomList.tencent }}</a
            >&nbsp;&nbsp;&nbsp;
          </div>
          <!--        <div>郑州制谱网文化传媒有限公司©版权所有  &nbsp;  豫ICP备16036420号</div>-->
          <div style="display: flex; justify-content: center">
            <div>{{ bottomList.company }}</div>
            &nbsp;&nbsp;&nbsp;
            <div @click="onRecord" class="beian">{{ bottomList.filings }}</div>
          </div>
        </div>
      </div>
      <div class="footer_back" @mouseenter="arrow()" @mouseleave="arrowOut">
        <el-backtop class="backTop" :bottom="80">
          <div>
            <img
              src="../assets/arrow(1).png" @dragstart.prevent
              v-if="arrowIndex == 1"
              style="width: 20px; height: 20px"
            />
            <img
              src="../assets/arrow.png" @dragstart.prevent
              v-if="arrowIndex == 2"
              style="width: 20px; height: 20px"
            />
          </div>
        </el-backtop>
      </div>
      <el-dialog
        title="请填写预约内容"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <el-form
          ref="ruleForm"
          :model="query"
          label-width="80px"
          :rules="rules"
        >
          <el-form-item label="联系人" prop="nickName">
            <el-input
              v-model="query.nickName"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="query.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="notes">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入备注"
              v-model="query.notes"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <el-button
            style="width: 100px; height: 30px; line-height: 0px"
            type="primary"
            @click="buttonClick('ruleForm')"
            >提交</el-button
          >
          <el-button
            style="width: 100px; height: 30px; line-height: 0px"
            @click="centerDialogVisible = false"
            >取消</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  reservationApi,
  backgroundColor,
  bottomChange,
} from "@/api/musicScore";

export default {
  name: "pcfooter",
  data() {
    return {
      colour: "",
      bottomList: [],
      query: {
        nickName: "",
        phone: "",
        notes: "",
      },
      rules: {
        nickName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            pattern:
              /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        notes: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      centerDialogVisible: false,
      show: false,
      arrowIndex: 2,
    };
  },
  mounted() {
    backgroundColor().then((res) => {
      this.colour = res.data.colour;
    });
    bottomChange().then((res) => {
      this.bottomList = res.data;
    });
  },
  methods: {
    putKonge(Text){
      const oneText = Text.toString()
      return oneText.replace(/ /g ,'')
    },
    go(qqNum){
      window.open('https://wpa.qq.com/msgrd?v=3&uin='+this.putKonge(qqNum)+'&site=qqq&menu=yes')
    },
    shareClick() {
      this.$nextTick(() => {
        window.scrollTo({
          top: 0, //回到底部
          behavior: "smooth", //smooth 平滑；auto:瞬间
        });
      });
    },
    buttonClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          reservationApi(this.query)
            .then(() => {
              this.centerDialogVisible = false;
              this.$message({
                showClose: true,
                message: "提交成功!",
                type: "success",
              });
            })
            .catch(() => {
              this.centerDialogVisible = false;
            });
        } else {
          return false;
        }
      });
    },
    onRecord() {
      // window.open("https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=16036420")
      window.open('https://beian.miit.gov.cn')
    },
    arrow() {
      this.arrowIndex = 1;
      // console.log(this.arrowIndex);
    },
    arrowOut() {
      this.arrowIndex = 2;
      // console.log(this.arrowIndex);
    },
  },
};
</script>

<style lang="less">
.footer {
  padding: 30px 0;
  height: 340px;
  color: white;
  .footer_top {
    display: flex;
    justify-content: space-between;
  }
  .footer_left {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 40%;
    .footer_text {
      div {
        font-size: 16px;
        margin-top: 4px;
        cursor: pointer;
      }
    }
    .footer_text_footer {
      position: relative;
      top: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      div {
        margin-top: 10px;
      }
      img {
        margin-top: 4px;
        width: 60px;
        height: 60px;
      }
    }
  }
  .qq:hover {
    .footer_QQ_hover,
    .footer_gap_hover {
      cursor: pointer;
      color: #409eff;
      //text-decoration: underline;
    }
  }
  .footer_right {
    width: 55%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .gap {
      height: 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
    }
    .gapHover {
      height: 44px;
      display: flex;
      color: white;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      //border:1px solid white;
    }
    .gapHover:hover {
      height: 44px;
      display: flex;
      color: #409eff;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      .footer_QQ_hover {
        cursor: pointer;
        color: #409eff;
        text-decoration: underline;
      }
      //border:1px solid white;
    }
    img {
      width: 30px;
      height: 36px;
      margin-right: 8px;
    }
    .qq {
      display: flex;
      align-items: center;
    }
    .footer_border {
      padding-bottom: 58px;
      cursor: pointer;
      font-size: 16px;
      color: white;
    }
  }
  .footer_bottom {
    margin-top: 40px;
    .warn {
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: center;
      font-size: 18px;
    }
    .warn_text {
      margin-top: 20px;
      text-align: center;
      div {
        margin-bottom: 10px;
      }
    }
  }
}
.share {
  width: 150px;
  margin-top: 20px;
  position: absolute;
}
.share_div {
  border: 1px solid white;
  background-color: white;
  height: 40px;
  color: black;
  width: 80px;
  border-radius: 4px;
  text-align: center;
  line-height: 35px;
  margin-bottom: 5px;
}
.backTop {
  width: 60px;
  height: 60px;
  margin-right: 30px;
  border: 1px solid #409eff;
}
.backTop:hover {
  background-color: #409eff;
}
.footer_text_hover {
  width: 100%;
  display: inline-block;
  text-align-last: justify;
  color: white;
}
.footer_text_hover:hover {
  color: #409eff;
  text-decoration: underline;
}
.footer_QQ_hover {
  font-size: 16px;
  color: white;
}
//.footer_QQ_hover:hover{
//  color:#409eff;
//  text-decoration: underline;
//}
.footer_gap_hover {
  cursor: pointer;
  color: white;
  font-size: 16px;
}
//.footer_gap_hover:hover{
//  color:#409eff;
//  text-decoration:underline;
//}
.qqvxStyle {
  color: white;
}
.qqvxStyle:hover {
  color: #409eff;
  text-decoration: underline;
}
.linkStyle {
  color: white;
}
.linkStyle:hover {
  color: #409eff;
  text-decoration: underline;
}
.beian:hover{
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
