<template>
  <div class="my-carousel-view">
    <div class="title" v-if="title">{{ title }}</div>
    <el-carousel
        :height="height"
        :autoplay="autoplay"
        indicator-position="outside"
    >
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <div class="box container">
          <div class="box-item" v-for="i in item" :key="i.id">
            <el-image
                class="custom-image"
                :src="src+i.url"
                :preview-src-list="imgList"
                @dragstart.prevent>
            </el-image>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>

</template>

<script>
import { setTwoDimensionalArray } from "@/utils";
export default {
  name: "appcarousel",
  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },
    pageNum: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
    title:{
        type:String,
        default:''
    },
    height:{
        type:String,
        default:'680px'
    }
  },
  data() {
    return {
      imgList:[],
      dataList: [],
      src:this.$image
    };
  },
  created() {
    // this.list.map(item=>{
    //   for (let i = 0; i < 2; i++) {
    //     this.list.push(item)
    //   }
    // })
    if(this.height === "1020px"){
      this.dataList = setTwoDimensionalArray(this.list,15);
      this.dataList.map(item=>{
        item.map(items=>{
          this.imgList.push(this.$image+items.url)
        })
      })
    }else {
      this.dataList = setTwoDimensionalArray(this.list,10);
      this.dataList.map(item=>{
        item.map(items=>{
          this.imgList.push(this.$image+items.url)
        })
      })
    }

  },
};
</script>

<style lang="less" scoped>
.el-image-viewer__wrapper{
  position: absolute;
  .el-image-viewer__canvas{
    position: absolute;
    height: 80%;
    top: 10%;
  }
}

.my-carousel-view {
  background-image: linear-gradient(rgb(242,242,242),white);
  padding-top: 20px;
  padding-bottom: 18px;
    .title{
        margin: 20px 0 10px 0;
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        color: rgba(80, 80, 80, 1);
    }
  /deep/.el-carousel__arrow--left {
    display: none !important;
  }
  .el-carousel__arrow--left {
    left: 158px;
  }
  .el-carousel__arrow--right{
    right: 158px;
  }
  .el-icon-arrow-left,.el-icon-arrow-right{
    margin-left: -4px;
    font-size: 60px;
  }
  /deep/.el-carousel__arrow--right {
    display: none !important;
  }
  /deep/.el-carousel__indicators {
    .el-carousel__indicator {
      .el-carousel__button {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        // display: none;
        background: #696767;
      }
    }
  }
  .el-carousel__arrow--left{
    height:150px;
    width:50px;
    border-radius: 5px;
  }
  .el-carousel__arrow--right{
    height:150px;
    width:50px;
    border-radius: 5px;
  }
  .el-carousel__indicators{
    .el-carousel__indicator{
      .el-carousel__button{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        display: none;
      }
    }
  }
}

.el-carousel__item {

  .box{
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    // width: 63%;
    .box-item {
      width: 18%;
      height:297px;
      margin-top: 25px;
      margin-right: 2.5%;
      margin-bottom: 0;
      // margin-left: 15px;
      box-shadow:  0 0 10px #d5d5d5;
      cursor: pointer;
      img{
      width: 100%;
      height: 100%;
    }
    }
    .box-item:nth-child(5n){
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 1250px) {
  
  .my-carousel-view /deep/.el-carousel .el-carousel__container{
    height: 550px!important;
  }
  .box-item {
    height: 230px!important;
    .custom-image{
      width: 100%;
      height: 100%;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.custom-image{
  height:297px;
  width: 100%;
}

.el-image-viewer__btn{
  bottom: 12px;
}

</style>
